<template>
  <v-responsive>
    <SubMenuCustomerMobile v-if="$vuetify.breakpoint.smAndDown"></SubMenuCustomerMobile>
    <v-container :fluid="$vuetify.breakpoint.lgAndDown" style="max-width:1440px;"> 
      <v-row>
        <v-col cols="12" sm="3" flat tile v-if="$vuetify.breakpoint.mdAndUp">
          <v-container class="pa-0">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0 pl-6" flat tile>
                <h2
                  class="
                    bx_pagetitle
                    float-left
                    mt-5
                    mb-3
                    blue--text
                    text--darken-4
                  "
                >
                  {{ breadcrumbs[1].text }}
                </h2>
              </v-card>
            </v-card>
          </v-container>
          <v-divider></v-divider>

          <SubMenuCustomer></SubMenuCustomer>
        </v-col>

        <v-col cols="12" sm="12" md="9" :class="$vuetify.breakpoint.smAndDown? 'mt-0 pt-0':''">
          <v-container class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
            <v-card class="d-flex justify-space-between" flat tile>
              <v-card class="pa-0" flat tile>
                <h2 class="bx_pagetitle float-left mt-5 mb-3">
                  {{ breadcrumbs[2].text }}
                </h2>
              </v-card>
              <v-card class="pa-0" flat tile v-if="$vuetify.breakpoint.mdAndUp">
                <v-breadcrumbs :items="breadcrumbs" class="float-right mt-2">
                  <template v-slot:divider>
                    <v-icon>mdi-chevron-right</v-icon>
                  </template>
                </v-breadcrumbs>
              </v-card>
            </v-card>
          <v-divider></v-divider>
          </v-container>
          <v-sheet min-height="55vh">
            <div class="terms_inner pa-5" v-html="content">
            </div>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-responsive>
</template>

<script>
//import HelloWorld from "../components/HelloWorld";

export default {
  name: "Terms",
  components: {
    //HelloWorld,
  },
  data: () => ({
    breadcrumbs: [
      { text: "홈" },
      { text: "고객센터" },
      { text: "이용약관" },
      //{ text: "당첨 통계", icon: "mdi-menu-right" },
    ],
    content:null,
  }),
  mounted() {
    //페이지 시작하면은 자동 함수 실행
    this.getContent();
  },
  methods: {
    getContent() {
      const self = this;

              
              self.$http
            .get(
              "//lotto7777.iwinv.net/api/page/?id=policy_terms"
            )
            .then((res) => {
              console.log(res);

              if (res.status === 200) {

                console.log('getContent',res.data);
                  self.content = res.data;
              
              } else {
                alert("실행중 실패했습니다.\n다시 이용해 주세요.");
              }

            });




    },




  },
};
</script>
<style lang="scss">
.terms_inner {
  font-size: 13px;
  margin-bottom: 50px;
}
.terms_inner h4 {
  margin-top: 30px;
  margin-bottom: 5px;
}
</style>
